import { Link } from 'gatsby';
import styled from 'styled-components';

import { Container } from '@components/global';

export const Nav = styled.nav.attrs(props => ({
  className: props.className,
}))`
  &.pop-out {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.5s ease-in;
  }
  & {
    padding: 16px 0;
    position: fixed;
    background-color: ${props => props.theme.color.white.regular};
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: all 0.5s ease-out;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  a {
    text-decoration: none;
    opacity: 0.7;
    color: ${props => props.theme.color.black.regular};
  }
  &.active {
    a {
      opacity: 1;
    }
  }
`;

export const NavDropdownLink = styled(Link)`
  & {
    padding: 10px 20px;
    :hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
`;

export const NavDropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${props => props.theme.color.white.regular};
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
  min-width: 100px;
  z-index: 1;
  ${({ open }) =>
    open &&
    `
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      `};
`;

export const Hamburger = styled.div.attrs(props => ({
  className: props.className,
}))`
  margin: 0 8px;
  & .close {
    transform: rotate(180deg);
  }
  &.close .btn-line:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
    border-radius: 30px;
  }
  &.close .btn-line:nth-child(2) {
    opacity: 0;
  }
  &.close .btn-line:nth-child(3) {
    transform: rotate(-45deg) translate(4px, -4px);
    border-radius: 30px;
  }
`;

export const BtnLine = styled.div`
  & {
    height: 2px;
    width: 20px;
    background: black;
    margin: 0 0 5px 0;
    transition: all 0.2s ease-out;
  }
`;

export const NavDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const MobileMenu = styled.div`
  width: 100%;
`;

export const MobileMenuContainer = styled(Container)`
  padding: 0 30px;
  margin-bottom: 30px;
`;

export const Mobile = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
  ${props =>
    props.hide &&
    `
    display: block;
    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;
