import styled from 'styled-components';
import { Grid, GridImage } from '@components/global';

export const ContentDiv = styled.div`
  p {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
  }
`;

export const HighlightGrid = styled(Grid)`
  &:nth-child(2) {
    margin-top: 48px;
  }

  &:nth-child(3) {
    margin-bottom: 72px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    ${GridImage} {
      order:-1;
    }
  }
`;

export const TagContainer = styled.div`
  margin: auto auto 12px;
`;

export const Calendar = styled.h4`
  margin: 8px auto;
`;

