import styled from 'styled-components';
import { Container } from '@components/global';
import Img from 'gatsby-image';

export const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;
`;

export const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};
  text-align: right;

  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`;

export const StyledLogo = styled(Img)`
  margin-left: auto;
  margin-right: 0;
  width: 125px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 45%;
  }
`;

export const HighlightPage = styled.div`
  ${props => props.theme.font_size.large};

  ul {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
    margin-top: 2px;
  }

  a {
    text-decoration: none;
    color: inherit;
    border: none;
    display: flex;
  }

  span {
    ${props => props.theme.font_size.small};
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
    ul {
      align-items: center;
    }
  }
`;

export const HighlightContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;

  @media(max-width: ${props => props.theme.screen.md}) {
    width: 75%;
  }
`; 

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;
