import React from 'react';
import { FormInputDiv } from './style';

const FormInput = ({ label, type, data, noMargin }) => {
  return (
    <FormInputDiv noMargin={noMargin}>
      <p className="form-label">{label}</p>
      <input
        className="form-input"
        type={type}
        value={data.value}
        onChange={data.onChange}
        required
      />
    </FormInputDiv>
  );
};

export const FormTextArea = ({ label, data, rows }) => {
  return (
    <FormInputDiv>
      <p className="form-label">{label}</p>
      <textarea
        className="form-text-area"
        value={data.value}
        onChange={data.onChange}
        rows={rows}
        required
      />
    </FormInputDiv>
  );
};

export default FormInput;
