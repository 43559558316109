import styled from 'styled-components';

// @Todo - options on Select tag width overflow
export const FormSelectDiv = styled.div`
  select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.color.black.lighter} !important;
    outline: none !important;
    margin-bottom: 1rem;
    padding: 5px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    option {
      width: 80% !important;
    }
  }
`;
