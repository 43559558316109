import styled from 'styled-components';
import { Container } from '@components/global';

export const SDContainer = styled(Container)`
  width: 50%;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    text-align: justify;
  }
`;

export const Paragraph = styled.p`
  margin: 20px;
`;
