import React from 'react';
import { HighlightDiv } from './style';

const Highlight = ({ title, desc, subdesc }) => {
  return (
    <HighlightDiv>
      <p>{title}</p>
      <p>{desc}</p>
      <p>{subdesc}</p>
    </HighlightDiv>
  );
};

export default Highlight;
