const PAGE = [
  {
    title: 'Beranda',
    link: '/',
    sections: [
      {
        sectionLink: '#about',
        text: 'Tentang SRE',
      },
      {
        sectionLink: '#event',
        text: 'Event Terbaru',
      },
      {
        sectionLink: '#highlights',
        text: 'Highlight',
      },
      {
        sectionLink: '#kolaborasi',
        text: 'Kolaborasi',
      },
    ]
  },
  // {
  //   title: 'Proyek & Publikasi',
  //   link: '/projectsnpublications',
  //   sections: [
  //     {
  //       sectionLink: '#projects',
  //       text: 'Proyek',
  //     },
  //     {
  //       sectionLink: '#publications',
  //       text: 'Publikasi',
  //     },
  //   ]
  // },
  {
    title: 'Kegiatan',
    link: '/events',
    sections: [
      {
        sectionLink: '?type=Event',
        text: 'Event',
      },
      {
        sectionLink: '?type=Webminar',
        text: 'Webminar',
      },
    ]
  },
  {
    title: 'Tentang',
    link: '/about',
    sections: [
      {
        sectionLink: '#sre',
        text: 'SRE',
      },
      {
        sectionLink: '#visimisi',
        text: 'Visi & Misi',
      },
      {
        sectionLink: '#contact',
        text: 'Contact Us',
      },
    ]
  },
];

export default PAGE;
