import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  word-wrap: break-word;

  & > h2:first-of-type {
    text-align: center;
  }

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};

  ${props =>
    props.flex &&
    `
    display: flex !important;
    justify-content: space-between;
    @media (max-width: ${props.theme.screen.md}) {
      flex-direction: column;
    }
  `};

  ${props =>
    props.grid &&
    `
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    justify-items: center;
    margin-top: 36px;
    @media (max-width: ${props.theme.screen.md}) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${props.theme.screen.sm}) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const Section = styled.section`
  padding: 128px 0;
  overflow: hidden;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 96px 0;
  }

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === 'secondary'
        ? props.theme.color.white.dark
        : props.theme.color.primary
    }`};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 30px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 84px 0 64px;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h3 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin: 96px 12px;

    ${props =>
      props.inverse &&
      `
        ${GridImage} {
          order: 2;
        }
    `}
  }
`;

export const GridImage = styled.div`
  margin: 0;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  img {
    max-width: 100%;
    min-height: 100%;
    border-radius: inherit;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    height: 400px;
    overflow: hidden;
  }
`;

export const RoundedImg = styled(Img)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 25px;
`;

export const Button = styled.button`
  background-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.white.regular};
  opacity: 0.85;
  padding: 10px 20px;
  border-radius: 8px;
  transition: all 0.4s;

  &:hover {
    opacity: 1;
  }

  ${props =>
    props.big &&
    `
    padding: 15px 18px;
    font-weight: bold;
  `}
`;

export const Tag = styled.button`
  position: initial;
  background-color: ${props => props.color};
  border-radius: 8px;
  padding: 6px 12px;
  margin: 5px auto 0;
  font-weight: 700;
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.color.black.regular};
`;
