import React from 'react';
import { navigate } from '@reach/router';
import { ButtonTag } from './style';

const Tag = ({ color, by, type, back }) => {
  let [query, isType] = by ? [by, false] : [type, true];
  const navigateTo = () => {
    let url = '/events?';
    url += isType ? (`type=${query}`) : (`by=${query}`);
    navigate(url+'#allevents');
  }
  
  return (
    <ButtonTag color={color} onClick={navigateTo}>
      {query}
    </ButtonTag>
  );
}

export default Tag;