import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { TitleLink } from '@components/global';

import {
  FooterWrapper,
  Copyright,
  StyledLogo,
  HighlightPage,
  HighlightContainer,
  StyledContainer,
} from './style';
import PAGE from './content';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        sre_logo: file(
          sourceInstanceName: { eq: "images" }
          name: { eq: "srelogo" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <HighlightContainer>
              {PAGE.map(({ title, link, sections }) => (
                <HighlightPage key={link}>
                  <TitleLink to={link}>
                    <h3>{title}</h3>
                  </TitleLink>
                  <ul>
                    {sections.map(({ sectionLink, text }, index) => (
                      <li key={index}>
                        <TitleLink to={link+sectionLink} key={sectionLink}>
                          <span>{text}</span>
                        </TitleLink>
                      </li>
                    ))}
                  </ul>
                </HighlightPage>
              ))}
            </HighlightContainer>
            <Copyright>
              <StyledLogo
                fluid={data.sre_logo.childImageSharp.fluid}
              />
              © 2020 SRE, All Rights Reserved
            </Copyright>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

export default Footer;
