import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import {
  Nav,
  NavItem,
  NavDropdown,
  NavDropdownContent,
  NavDropdownLink,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  MobileMenuContainer,
  Mobile,
  Hamburger,
  BtnLine,
} from './style';
import NAV_ITEMS from './content';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [teamMenuOpen, setTeamMenuOpen] = useState(false);
  const image = useStaticQuery(graphql`
  query {
    sre_logo: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "srelogo" }
    ) {
      childImageSharp {
        fluid(maxWidth: 75) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
  `);

  const toggleMobileMenu = () => {
    mobileMenuOpen ? closeMobileMenu() : setMobileMenuOpen(true);
    document.getElementById('menu-btn').classList.toggle('close');
  };

  const closeMobileMenu = () => {
    setTeamMenuOpen(false);
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const toggleTeamMenu = e => {
    e.preventDefault();
    setTeamMenuOpen(!teamMenuOpen);
  };

  const getNavAnchorLink = item =>
    item.child ? (
      <NavDropdown>
        <Link
          to={item.path}
          onClick={toggleTeamMenu}
          activeClassName="nav-active"
          partiallyActive
        >
          {item.name}{' '}
          {teamMenuOpen ? (
            <IoIosArrowUp size={12} />
          ) : (
            <IoIosArrowDown size={12} />
          )}
        </Link>
        <NavDropdownContent open={teamMenuOpen}>
          {item.child.map(child => (
            <NavDropdownLink
              key={child.path}
              to={item.path + child.path}
              onClick={closeMobileMenu}
              activeClassName="nav-active"
            >
              {child.name}
            </NavDropdownLink>
          ))}
        </NavDropdownContent>
      </NavDropdown>
    ) : (
      item.external ? (
        <a
          href={item.path}
        >
          {item.name}
        </a>
      ) : (
        <Link
        to={item.path}
        onClick={closeMobileMenu}
        activeClassName="nav-active"
        partiallyActive={item.path==='/events'}
      >
        {item.name}
      </Link>
      )
    );

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <ul>
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem.name}>{getNavAnchorLink(navItem)}</NavItem>
        ))}
      </ul>
    </NavListWrapper>
  );

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const navbar = document.getElementById('navbarscroll');
      window.scrollY === 0
        ? navbar.classList.remove('pop-out')
        : navbar.classList.add('pop-out');
    });
  }, []);

  return (
    <Nav id="navbarscroll">
      <StyledContainer>
        <Link to="/">
          <Img
            fluid={image.sre_logo.childImageSharp.fluid}
            style={{ width: 75 }}
          />
        </Link>
        <Mobile>
          <button onClick={toggleMobileMenu} style={{ color: 'black' }}>
            <Hamburger id="menu-btn">
              <BtnLine className="btn-line"></BtnLine>
              <BtnLine className="btn-line"></BtnLine>
              <BtnLine className="btn-line"></BtnLine>
            </Hamburger>
          </button>
        </Mobile>
        <Mobile hide>{getNavList({})}</Mobile>
      </StyledContainer>
      <Mobile>
        {mobileMenuOpen && (
          <MobileMenu>
            <MobileMenuContainer>
              {getNavList({ mobile: true })}
            </MobileMenuContainer>
          </MobileMenu>
        )}
      </Mobile>
    </Nav>
  );
};

export default Navbar;