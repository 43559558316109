import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

const SEO = ({ page }) => {
  const SEO_DATA = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          author
          description
          keywords
          title
          seotitle
          img
          url
        }
      }
    }
  `).site.siteMetadata;

  return (
    <Helmet>
      <meta property="og:title" content={SEO_DATA.seotitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta
        property="og:image"
        itemprop="image"
        content={`${SEO_DATA.url}${SEO_DATA.img}`}
      />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={SEO_DATA.url} />
      <meta name="twitter:title" content={SEO_DATA.seotitle} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="url" content={SEO_DATA.url} />
      <meta name="image" content={`${SEO_DATA.url}${SEO_DATA.img}`} />
      <title>{`${page} - ${SEO_DATA.title}`}</title>
      <html lang="id" />
    </Helmet>
  );
};

export default SEO;
