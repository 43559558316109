import React from 'react';
import { Section } from '@components/global';
import { SDContainer, Paragraph } from './style';

export default ({ id, content, accent }) => {
  return (
    <Section id={id} accent={accent}>
      <SDContainer>
        <h2>{content.title}</h2>
        {content.text.map((item, idx) => (
          <Paragraph key={idx}>{item}</Paragraph>
        ))}
      </SDContainer>
    </Section>
  );
};
