import React from 'react';
import { Tag } from '@common';
import { GridImage, Button, TitleLink } from '@components/global';
import { ContentDiv, Calendar, TagContainer, HighlightGrid } from './style';
import { changeToUrl, colorMap, useImage } from '@utils';
import { Link } from 'gatsby';

const Image = ({ data, alt }) => (
  <GridImage>
    <img loading="lazy" src={data} alt={alt} />
  </GridImage>
);

const Content = ({ title, date, desc, tags }) => (
  <ContentDiv>
    <TitleLink to={`events/${changeToUrl(title)}`}>
      <h3>{title}</h3>
    </TitleLink>
    <Calendar>
      <span role="img" aria-label="calendar">
        📅
      </span>{' '}
      {date}
    </Calendar>
    <p>{desc}</p>
    <TagContainer>
      {tags.map((tag, idx) => (
        <Tag key={idx} color={colorMap[tag]} by={tag} />
      ))}
    </TagContainer>
    <Link to={`events/${changeToUrl(title)}`}>
      <Button>Baca Lebih Lanjut</Button>
    </Link>
  </ContentDiv>
);

const Event = ({ title, date, desc, tags, inverse }) => {
  const imgUrl = useImage(changeToUrl(title));

  return (
    <HighlightGrid inverse={inverse}>
      {inverse ? (
        <React.Fragment>
          <Image data={imgUrl} alt={title} />
          <Content title={title} date={date} desc={desc} tags={tags} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Content title={title} date={date} desc={desc} tags={tags} />
          <Image data={imgUrl} alt={title} />
        </React.Fragment>
      )}
    </HighlightGrid>
  );
};

export default Event;
