import styled from 'styled-components';

export const HighlightDiv = styled.div`
  text-align: center;
  p {
    line-height: 36px;
    ${props => props.theme.font_size.large};
  }
  p:nth-of-type(2) {
    ${props => props.theme.font_size.larger};
    font-weight: 700;
  }
  p:nth-of-type(3) {
    ${props => props.theme.font_size.regular};
  }
  
  @media(max-width: ${props => props.theme.screen.md}) {
    margin: 12px auto;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    p {
      ${props => props.theme.font_size.regular};
    }
    p:nth-of-type(2) {
      ${props => props.theme.font_size.large};
    }
    p:nth-of-type(3) {
      ${props => props.theme.font_size.small};
    } 
  }
`;