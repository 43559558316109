import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const Background = ({ imageComponent, children }) => {
  const background = imageComponent
    ? imageComponent.node.childImageSharp.fluid
    : '#000';
  return (
    <BackgroundImage
      fluid={[
        `linear-gradient(135deg, #fff 35%, hsla(0, 0%, 100%, 0.5))`,
        `linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.2) 30%, hsla(0, 0%, 100%, 0.2) 55%, #fff)`,
        background,
      ]}
    >
      {children}
    </BackgroundImage>
  );
};

export default Background;
