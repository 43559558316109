import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  padding-top: 24px;
  ${props => 
    props.includeHeading &&
    `
    height: 87.5vh;
    padding-top: 96px;
  `}
`;

export const Content = styled.div`
  display: flex;
  align-content: flex-end;
  min-height: 75vh;
  align-items: center;
  width: 50%;

  p {
    ${props => props.theme.font_size.regular};

    @media(max-width: ${props => props.theme.screen.md}) {
      ${props => props.theme.font_size.small};
    }
  }
`;
