import styled from 'styled-components';

export const CardWrapper = styled.div`
  ${props =>
    props.elevated &&
    `
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.5s;

    &:hover {
      transform: scale(1.01);
    }
  `}
  border-radius: 8px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  min-height: 3rem;
  position: absolute;
  width: 100%;
  bottom: 0.5rem;
  padding-right: 1rem;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px auto;
`;

export const ImageWrapper = styled.div`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 250px;
  overflow: hidden;
  img {
    max-width: 100%;
    min-height: 100%;
  }
`;

export const CardData = styled.div`
  padding: 24px 36px;
  /* display: 'flex';
  flex-direction: 'column'; */
  h3 {
    text-align: center;
  }
  h4 {
    margin-top: 5px;
    text-align: right;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
  }
  ul {
    list-style: none;
    padding-inline-start: 0px;
  }
  li {
    margin: 1rem 0rem;
    display: flex;
    align-items: center;
  }
`;

export const CardListData = styled(CardData)`
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 12px 0 12px 0;
  }
`;
export const OfficerImgGrid = styled.div`
  margin: 0;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display:flex;
  justify-content:center;

  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    border-radius: inherit;
  }

  /* @media (min-width: ${props => props.theme.screen.md}) {
    height: 400px;
    overflow: hidden;
  } */
`;
