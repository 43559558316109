import React from 'react';
import { FormSelectDiv } from './style';

const FormSelect = ({
  title,
  selected,
  setSelected,
  optionList,
  resetSuccess,
}) => (
  <FormSelectDiv>
    <p className="form-label">{title}</p>
    <select
      name={title}
      value={selected}
      onChange={e => {
        setSelected(e.target.value, e.target.name);
      }}
      style={{ position: 'relative' }}
    >
      <option value="Select" disabled hidden>
        Select
      </option>
      {optionList.map((item, idx) => (
        <option key={idx} value={item}>
          {item.replace('_', '-')}
        </option>
      ))}
    </select>
  </FormSelectDiv>
);

export default FormSelect;
