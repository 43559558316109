import React, { useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import {
  FaLine,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaSearchLocation,
} from 'react-icons/fa';
import loadingImages from '@images/background/loading.png';

export const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  };
  return { value, setValue, onChange: handleChange };
};

export const changeToUrl = str => {
  return str
    .replace(/:|\?|"|'/g, '')
    .replace(/ |-/g, '_')
    .toLowerCase();
};

export const useImage = (
  titleUrl,
  path = 'gs://sre-website-a43e8.appspot.com/events/'
) => {
  const [imgUrl, setImgUrl] = React.useState(loadingImages);
  useEffect(() => {
    setImgUrl(loadingImages);
    firebase
      .storage()
      .refFromURL(`${path}${titleUrl}.jpg`)
      .getDownloadURL()
      .then(url => setImgUrl(url))
      .catch(err => console.log(err));
  }, [titleUrl, path]);

  return imgUrl;
};

export const colorMap = {
  Webminar: '#FFA500',
  Event: '#FFB6C1',
  'All Teams': '#68b0ab',
  ITB: '#87CEEB',
  UI: '#FFD700',
};

export const socialIconMap = {
  email: FaEnvelope,
  line: FaLine,
  instagram: FaInstagram,
  linkedin: FaLinkedin,
  alamat: FaSearchLocation,
};

export const getTime = timestamp =>
  new Date(Number(timestamp)).toLocaleTimeString('id', {
    hour: '2-digit',
    minute: '2-digit',
  });

export const handleDate = (date, timestamp, eventType) => {
  const dateArr = date.split(' ');
  const [bulan, tahun] = dateArr.slice((dateArr.length - 2), dateArr.length);
  const time = getTime(timestamp);
  const hourComponent = time !== '00.00' ? ` - Pukul ${time} WIB` : '';
  const newDate = eventType === 'Series' ?  `${bulan} ${tahun}` : date;
  return newDate + hourComponent;
}