const NAV_ITEMS = [
  {
    name: 'Beranda',
    path: '/',
  },
  {
    name: 'Tim',
    path: '/teams',
    child: [
      {
        name: 'Semua',
        path: '',
      },
      {
        name: 'ITB',
        path: '/itb',
      },
      {
        name: 'UI',
        path: '/ui',
      },
      {
        name: 'ITS',
        path: '/its',
      },
      {
        name: 'UNTAN',
        path: '/untan',
      },
      {
        name: 'UNSRI',
        path: '/unsri',
      },
      {
        name: 'UNHAS',
        path: '/unhas',
      },
      {
        name: 'UNDIP',
        path: '/undip',
      },
      {
        name: 'Tel-U',
        path: '/tel-u',
      },
      {
        name: 'UNS',
        path: '/uns',
      },
      {
        name: 'ITERA',
        path: '/itera',
      },
      {
        name: 'UB',
        path: '/ub',
      },
      {
        name: 'UGM',
        path: '/ugm',
      },
    ],
  },
  // {
  //   name: 'Proyek & Publikasi',
  //   path: '/projectsnpublications',
  // },
  {
    name: 'Kegiatan',
    path: '/events',
  },
  {
    name: 'Tentang',
    path: '/about',
  },
  {
    name: 'Astra',
    path: 'https://astra2021.srenasional.com/',
    external: true
  },
];

export default NAV_ITEMS;
