import React from 'react';
import { Link } from 'gatsby';
import { Tag } from '@common';
import { Button } from '@components/global';
import {
  CardWrapper,
  CardData,
  CardListData,
  ImageWrapper,
  SubtitleWrapper,
  OfficerImgGrid,
  ButtonContainer,
} from './style';
import { changeToUrl, useImage, colorMap, socialIconMap } from '@utils';

const Card = ({ title, subtitle, date, by, text, elevated }) => {
  const titleUrl = changeToUrl(title);
  const imgUrl = useImage(titleUrl);

  return (
    <CardWrapper elevated={elevated}>
      <ImageWrapper>
        <img loading="lazy" src={imgUrl} alt={title} />
      </ImageWrapper>
      <CardData>
        <h3>{title}</h3>
        <SubtitleWrapper>
          <div>
            {by.map(univ => (
              <Tag
                style={{ marginRight: '5px' }}
                key={univ}
                color={colorMap[univ]}
                by={univ}
              />
            ))}
          </div>
          <div>
            <Tag color={colorMap[subtitle]} type={subtitle} />
          </div>
        </SubtitleWrapper>
        <div style={{ margin: '5px auto' }}>
          <b>{date}</b>
        </div>
        <p style={{ height: '50px', marginBottom: '3.5rem' }}>{text}</p>
      </CardData>
      <ButtonContainer>
        <Link to={titleUrl}>
          <Button>Selengkapnya</Button>
        </Link>
      </ButtonContainer>
    </CardWrapper>
  );
};

export const CardListContent = ({ title, data, imgData }) => {
  return (
    <CardWrapper>
      {imgData}
      <CardListData>
        <h3>{`SRE ${title.replace('_', '-')}`}</h3>
        <ul>
          {Object.entries(data).map(([key, value]) => {
            const Icon = socialIconMap[key];
            return (
              <li key={value}>
                <div
                  style={{
                    margin: '0rem 1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon size={24} />
                </div>
                <div>{value}</div>
              </li>
            );
          })}
        </ul>
      </CardListData>
    </CardWrapper>
  );
};

export const OfficerCard = ({ title, elevated, subtitle, folderPath }) => {
  const Url = useImage(title, folderPath);
  return (
    <CardWrapper elevated={elevated}>
      <OfficerImgGrid>
        <img loading="lazy" src={Url} alt={title} />
      </OfficerImgGrid>
      <CardData>
        <h3 style={{ textAlign: 'center' }}>
          {title ? title.split('_')[0] : 'NULL'}
        </h3>
        {subtitle && (
          <h4 style={{ textAlign: 'center', marginTop: '1.5rem' }}>
            {subtitle}
          </h4>
        )}
      </CardData>
    </CardWrapper>
  );
};

Card.defaultProps = {
  elevated: true,
};

export default Card;
