import React from 'react';
import { Link } from './style';

const ExternalLink = ({ href, children, ...other }) => (
  <Link  href={href} {...other} rel="noreferrer noopener" target="_blank">
    {children}
  </Link>
);

export default ExternalLink;
