import styled from 'styled-components';

export const FormInputDiv = styled.div.attrs(props => ({
  className: props.className,
}))`
  & {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${props => !(props.noMargin) && `
      margin-bottom: 1rem;
    `}
  }

  & > * {
    max-width: 100%;
  }

  & .form-input,
  .form-text-area {
    border: 1px solid ${props => props.theme.color.black.lighter} !important;
    transition: all 0.2s ease-in-out;
    outline: none !important;
    border-radius: 5px;
  }

  & .form-input:focus,
  .form-text-area:focus {
    transform: scale(1.0025);
    border: 1px solid ${props => props.theme.color.black.regular} !important;
  }
`;
