const theme = {
  font: {
    primary: `'Montserrat', serif`,
    secondary: `'Poppins', sans-serif`,
  },
  font_size: {
    small: 'font-size: 1rem; line-height: 24px',
    regular: 'font-size: 1.234rem; line-height: 24px',
    large: 'font-size: 1.563rem; line-height: 32px',
    larger: 'font-size: 2.441rem; line-height: 48px',
    xlarge: 'font-size: 3.052rem; line-height: 56px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    primary: '#A3D1A3',
    secondary: '#008000',
    danger: '#DC3545'
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
