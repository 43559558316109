import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { SEO } from '@common';
import Header from '@sections/Header';
import Footer from '@sections/Footer';
import theme from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ children, page, includeHeading }) => (
  <ThemeProvider theme={theme}>
    <>
      <SEO page={page} />
      <GlobalStyles />
      <Header page={page} includeHeading={includeHeading} />
      {children}
      <Footer />
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
