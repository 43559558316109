import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from '@components/global';
import { Navbar, Background } from '@common';
import { HeaderWrapper, Content } from './style';

const Text = ({ queryComponent }) => {
  const header = queryComponent
    ? queryComponent.node
    : { title: 'NULL', subtitle: 'Lorem ipsum' };
  return (
    <div>
      <h1>{header.title}</h1>
      <br />
      <p>{header.subtitle}</p>
    </div>
  );
};

const Header = ({ page, includeHeading }) => (
  <StaticQuery
    query={graphql`
      query {
        background: allFile(
          filter: { sourceInstanceName: { eq: "background" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        text: allHeader {
          edges {
            node {
              title
              subtitle
              page
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper includeHeading={includeHeading}>
        <Navbar/>
        {includeHeading ? (
          <Background
            imageComponent={data.background.edges.find(
              image => image.node.name === page
            )}
          >
            <Container>
              <Content>
                <Text
                  queryComponent={data.text.edges.find(
                    o => o.node.page === page
                  )}
                />
              </Content>
            </Container>
          </Background>
        ) : null}
      </HeaderWrapper>
    )}
  />
);

Header.defaultProps = {
  includeHeading: true
}

export default Header;
